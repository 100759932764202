* {
  cursor: none;
  margin: 0;

  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  font-family: Sandoll Greta Sans Beta;
  font-style: normal;

  font-weight: 400;

  -webkit-user-drag: none;
}

a {
  text-decoration: none;
  color: inherit;
}


.link-white {
  color: white;
}

.link-white:hover {
  color: #4400CC;
  text-shadow:
    -0.12vh -0.12vh 0 #fff,
    0.12vh -0.12vh 0 #fff,
    -0.12vh 0.12vh 0 #fff,
    0.12vh 0.12vh 0 #fff;
}

.link-blue {
  color: #4400CC;
}

.link-blue:hover {
  color: white;
  text-shadow:
    -0.12vh -0.12vh 0 #4400CC,
    0.12vh -0.12vh 0 #4400CC,
    -0.12vh 0.12vh 0 #4400CC,
    0.12vh 0.12vh 0 #4400CC;

}

.link-yellow {
  color: yellow;
}

.link-yellow:hover {
  color: black;
  text-shadow:
    -0.12vh -0.12vh 0 yellow,
    0.12vh -0.12vh 0 yellow,
    -0.12vh 0.12vh 0 yellow,
    0.12vh 0.12vh 0 yellow;

}


p {
  line-height: 1.57;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

table tr td {
  border-collapse: collapse;
  padding: 0;
  border: none;
  margin: 0;
  text-indent: 0px;
}